import React, { useRef, useState } from "react";
import "./App.css";

export default function App() {
	const [queryText, setQueryText] = useState("");
	const [duration, setDuration] = useState("");
	const [videoSrc, setVideoSrc] = useState("");
	const [fileName, setFileName] = useState("");
	const fileInputRef = useRef();

	let video = document.getElementById("video");
	console.log(video);

	function handleQueryChange(x) {
		setQueryText(x.target.value);
		console.log(queryText, duration, fileInputRef.current.files[0]);
	}

	function handleDurationChange(x) {
		setDuration(x.target.value);
		setDuration(x.target.value);
		console.log(queryText, duration, fileInputRef.current.files[0]);
	}

	function renderVideo() {
		const video = document.getElementById("video");
		const reader = new FileReader();
		const file = fileInputRef.current.files[0];

        console.log(file.size)
        if (file.size > 200000000) {
            alert("Video Too Large!");
            return;
        }

		setFileName(fileInputRef.current.files[0].name);

		reader.addEventListener(
			"load",
			() => {
				video.src = reader.result;
			},
			false
		);

		if (file) {
			reader.readAsDataURL(file);
		}
		video.load();
	}

	async function sendData() {
		const url = "https://clip.nebsplay.space/clips/upload/";
		//"http://127.0.0.1:8080/clips/upload/"
		// "https://clip.nebsplay.space/clips/upload/"
		const formData = new FormData();

		const file = fileInputRef.current.files[0];
		if (file.size > 200000000) {
            alert("Video Too Large!");
		    return;
		}

		setVideoSrc(URL.createObjectURL(file));

		formData.append("query", queryText);
		formData.append("duration", duration);
		formData.append("video", file);

		const options = {
			method: "POST",
			body: formData,
		};

		let res = await fetch(url, options);

		const json = await res.json();
		const ticket = json.ticket_id;
		console.log(ticket)

		document.getElementById("queuePlacement").innerHTML = "Queue Placement: " + json.pos;

		let apiTimeout = setTimeout(fetchAPIData, 1000);

		const pollOptions = {
			method: "POST",
		};

		async function fetchAPIData() {
			let pollUrl = "https://clip.nebsplay.space/clips/get_video/" + ticket + "/";
			// "http://127.0.0.1:8080/clips/get_video/" + ticket + "/";
			// "https://clip.nebsplay.space/clips/get_video/" + ticket + "/";
			console.log(pollUrl)

			let res2 = await fetch(pollUrl, pollOptions);

			let json = await res2.json().catch((e) => e);
			console.log(json);

            let queuePos = json.pos;
	        if (queuePos == "-1" || queuePos == -1) {
	            document.getElementById("queuePlacement").innerHTML = "Finished!";
	        }
			document.getElementById("queuePlacement").innerHTML = "Queue Placement: " + json.pos;

			if (json?.video?.length === 2) {
				res = document.getElementById("result");
				video = document.getElementById("video");
				res.src = video.src;

				let start = json.video[0];
				let end = json.video[1];
				console.log(start);
				console.log(end);
                console.log(video.duration);

				res.currentTime = start;
				res.addEventListener("timeupdate", function () {
					if (this.currentTime >=  end || this.currentTime == video.duration) {
						this.currentTime = start;
						this.pause();
					}
				});
			} else {
				apiTimeout = setTimeout(fetchAPIData, 1000);
			}
		}
	}
	return (
		<div className='min-h-screen min-w-screen grid sm:grid-cols-2 sm:grid-rows-none grid-rows-2'>
			{/* First column */}
			<div className='bg-slate-50 border-slate-200 sm:border-r-2 sm:border-b-0 border-b-2 px-10 pb-5'>
				<header className='w-full pt-5 flex justify-center'>
					<div className='prose text-center'>
						<h1 className='title'>VideoCLIP</h1>
						<h2 className='subtitle'>Search videos using CLIP Embeddings</h2>
					</div>
				</header>
				<section className='w-full py-3 prose'>
					<form onSubmit={(e) => e.preventDefault()} className='flex flex-col'>
						<div>
							<h3>Upload Video (200 MB limit)</h3>
							<div className='flex gap-5 items-center'>
								<label className='p-1 px-3 bg-zinc-600 rounded-md text-white hover:bg-zinc-700 transition-colors cursor-pointer'>
									Upload
									<input
										type='file'
										name=''
										id='input_id'
										ref={fileInputRef}
										onChange={renderVideo}
										accept='video/*'
										className='hidden'
									/>
								</label>
								<strong>{fileName}</strong>
							</div>
						</div>
						<div>
							<h3>Query</h3>
							<input
								className='bg-white border-slate-200 border-2 outline-none rounded-md p-2 px-3'
								placeholder='Enter a query'
								type='text'
								value={queryText}
								onChange={handleQueryChange}
							/>
						</div>
						<div>
							<h3>Duration</h3>
							<input
								className='bg-white border-slate-200 border-2 outline-none rounded-md p-2 px-3'
								placeholder='Enter a duration'
								type='number'
								value={duration}
								onChange={handleDurationChange}
							/>
						</div>
						<div className='pt-6'>
							<button
								className='p-1 px-3 bg-zinc-600 rounded-md text-white hover:bg-zinc-700 transition-colors cursor-pointer'
								type='submit'
								onClick={sendData}
							>
								Submit
							</button>
						</div>
					</form>
				</section>
			</div>

			{/* Second column */}
			<div className='px-10 '>
				<div className='w-full h-full place-items-center grid grid-rows-2'>
					<div className='prose'>
						<h3>Input Video:</h3>
						<video id='video' controls />
					</div>

					<div className='prose'>
						<h3>Result Video:</h3>
						<video id='result' controls />
					</div>

					<div className='prose'>
						<h3 id="queuePlacement"></h3>
					</div>
				</div>
			</div>
		</div>
	);
}